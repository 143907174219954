<template>
    <BT-Blade-Items
        addBladeName="ordering-rules-group"
        bladeName="ordering-rules-groups"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        :headers="[
            { text: 'Name', value: 'groupName', title: 1, searchable: true }]"
        navigation="ordering-rules-groups"
        title="Ordering Rules"
        useServerPagination />
</template>

<script>
export default {
    name: 'Ordering-Rules-Groups',
    props: {
        bladesData: null
    }
}
</script>